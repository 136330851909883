<template>
    <component :is="this.dynamicComponent"/>
</template>

<script>
import SessionTracker from "@/../vue-components/helpers/SessionTracker.js";

// cms-page-selector #hot-reload-bugfix
export default {
    name: `cms-page-selector`,
    metaInfo() {
        if (this.cmsPage) {
            return this.cmsPage.getMeta();
        } else {
            return null;
        }
    },
    data() {
        return {
            loading: false
        }
    },
    async beforeRouteUpdate(to, from, next) {
        if (this.loading) {
            return;
        }
        this.loading = true;
        // console.log(`Before update`);
        // console.info(this.$route);
        // console.log(to, from);
        await this.$store.dispatch(`loadPage`, to);
        next();
        this.loading = false;
    },
    created() {
        if (this.dealerid) {
            SessionTracker.init({
                type: `cms`,
                dealerid: this.dealerid
            });
        }
    },
    computed: {
        dynamicComponent() {
            if (!this.cmsPage) {
                return () => import(`@/views/default-error-page.vue`);
            }

            switch (this.cmsPage.type) {
                case `map`:
                    return () => import(`@/views/cms-page-map.vue`);
            }

            if (this.cmsPage.blocks) {
                return () => import(`@/views/cms-page-blocks.vue`);
            }

            return () => import(`@/views/cms-page-html.vue`);
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
